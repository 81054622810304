import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { MdMenu } from "react-icons/md"
import { MdEmail } from "react-icons/md"
import { FaFacebookF } from "react-icons/fa"
import { FaPhone } from "react-icons/fa"

import { MAIN_COLOR, ACCENT_COLOR } from "../consts/color"
import HeaderBg from "../images/gridmarketing__header-gradient@2x.png"
import Infobar from "./infobar"
import { useSiteMeta } from "../hooks/siteMeta"

const StyledHeader = styled.header`
  padding: 1.40625em 0;
  background-image: url(${HeaderBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  transition: all 0.2s ease-in-out;
  transform: translate(0);

  &.is-open {
    transform: translate(-275px);
  }

  @media all and (min-width: 769px) {
    height: 115px;
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
`

const Logo = styled(Img)``

const LogoContainer = styled.div`
  max-width: 200px;
  width: 100%;

  @media all and (min-width: 769px) {
    max-width: 285px;
  }
`

const StyledNav = styled.nav`
  display: none;

  @media all and (min-width: 769px) {
    display: block;
  }
`
const NavLinks = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
`
const NavElement = styled.li`
  margin: 0;
  padding: 0 1.5625em;
  display: flex;
`
const Link = styled.a`
  font-size: 1.25em;
  text-decoration: none;
  color: ${MAIN_COLOR};
  transition: color 0.3s ease-in-out;
  align-self: center;

  &:hover {
    color: ${ACCENT_COLOR};
  }

  &.button {
    display: inline-block;
    font-weight: 700;
    color: ${ACCENT_COLOR};
    border: 3px solid ${ACCENT_COLOR};
    padding: 0.3125em;
    transition: all 0.3s ease-in-out;
    transition-property: color, background-color;

    &:hover {
      color: #fff;
      background-color: ${ACCENT_COLOR};
    }
  }
`
const StyledMdMenu = styled(MdMenu)`
  font-size: 1.9375em;

  @media all and (min-width: 769px) {
    display: none;
  }
`

const MobileNav = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  height: 100vh;
  width: 275px;
  background-color: ${ACCENT_COLOR};
  overflow-y: auto;
  transform: translateX(100%);
  transition: all .3s ease-in-out;
  z-index: 999999;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;

  &.is-open {
    transform: translateX(100%);
  }
`
const MobileLogoContainer = styled.div`
  width: 100%;
  text-align: center;
  padding: 1.875rem;
`
const MobileNavLinks = styled.ul`
  list-style-type: none;
  margin: 0;
`
const MobileNavElement = styled.li``
const MobileLink = styled(Link)`
  width: 100%;
  display: inline-block;
  color: #fff;
  padding: 10px;

  &.button {
    color: #fff;
    border: 3px solid #fff;
    width: inherit;
  }
`
const InfoContainer = styled.div`
  padding: 0 20px 30px 20px;
`
const GooglePartner = styled(Img)``
const ContactLink = styled(MobileLink)`
  font-size: 1em;
  padding: 10px 0;
`

const Header = ({ lightLogo, logo, google, menuHandler, isOpen }) => {
  const { phone, email } = useSiteMeta()
  return (
    <>
      <StyledHeader className={isOpen && "is-open"}>
        <Container>
          <LogoContainer>
            <Logo fluid={logo} fadeIn={false} loading='eager'/>
          </LogoContainer>
          <StyledMdMenu onClick={menuHandler} />
          <StyledNav>
            <NavLinks>
              <NavElement>
                <Link href="/#ismerd-meg">Ismerd meg!</Link>
              </NavElement>
              <NavElement>
                <Link href="/#miert-valaszd">Miért válaszd?</Link>
              </NavElement>
              <NavElement>
                <Link href="/#kiknek-ajanljuk">Kiknek ajánljuk?</Link>
              </NavElement>
              <NavElement>
                <Link className="button" href="/#kapcsolat">
                  Ajánlatkérés
                </Link>
              </NavElement>
            </NavLinks>
          </StyledNav>

          <MobileNav className={isOpen && "is-open"}>
            <MobileLogoContainer>
              <Logo fluid={lightLogo} fadeIn={false} loading='eager'/>
            </MobileLogoContainer>
            <MobileNavLinks>
              <MobileNavElement onClick={menuHandler}>
                <MobileLink href="/#ismerd-meg">Ismerd meg!</MobileLink>
              </MobileNavElement>
              <MobileNavElement onClick={menuHandler}>
                <MobileLink href="/#miert-valaszd">Miért válaszd?</MobileLink>
              </MobileNavElement>
              <MobileNavElement onClick={menuHandler}>
                <MobileLink href="/#kiknek-ajanljuk">
                  Kiknek ajánljuk?
                </MobileLink>
              </MobileNavElement>
              <MobileNavElement onClick={menuHandler}>
                <MobileLink href="/#kapcsolat" className="button">
                  Ajánlatkérés
                </MobileLink>
              </MobileNavElement>
            </MobileNavLinks>
            <InfoContainer>
              {/* <GooglePartner fluid={google} fadeIn={false}/> */}
              <ContactLink href={`tel:${phone}`}>
                <FaPhone /> {phone}
              </ContactLink>
              {/* <ContactLink href={`mailto:${email}`}>
                <MdEmail /> {email}
              </ContactLink> */}
              <ContactLink href='https://www.facebook.com/gridmarketing.hu' target="_blank" rel="noreferrer noopener">
                <FaFacebookF/> gridmarketing
              </ContactLink>
            </InfoContainer>
          </MobileNav>
        </Container>
      </StyledHeader>
      <Infobar google={google} />
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
