import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMeta = () => {
  const siteMeta = useStaticQuery(
    graphql`
      query SiteMeta {
        site {
          siteMetadata {
            title
            description
            phone
            email
          }
        }
      }
    `
  )

  return siteMeta.site.siteMetadata
}