/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import Header from "./header"
import "./layout.css"

const Main = styled.main`
  transition: all 0.2s ease-in-out;
  transform: translate(0);

  &.is-open {
    transform: translate(-275px);
  }
`
const Footer = styled.footer`
  background-color: #1a1a1a;
  padding: 1.875rem 0;
  text-align: center;
  transition: all 0.2s ease-in-out;
  transform: translate(0);

  &.is-open {
    transform: translate(-275px);
  }

  & .gatsby-image-wrapper {
    max-width: 220px;
    margin: 0 auto;

    @media all and (min-width: 768px) {
      max-width: 360px;
    }
  }
`
const Wrapper = styled.div`
  &.is-open {
    overflow: hidden;
    height: 100vh;
  }
`
const Overlay = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  width: calc(100% - 275px);

  &.is-open {
    visibility: visible;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
`

const Layout = ({ children }) => {
  const [isOpen, setMenu] = useState(false)
  const toggleMenu = () => setMenu(!isOpen)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      logo: file(relativePath: { eq: "gridmarketing__logo__black@2x.png" }) {
        src: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      google: file(
        relativePath: { eq: "gridmarketing__google partner badge@2x.png" }
      ) {
        src: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      lightLogo: file(
        relativePath: { eq: "gridmarketing__logo__white@2x.png" }
      ) {
        src: childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lightLogoFluid: file(
        relativePath: { eq: "gridmarketing__logo__white@2x.png" }
      ) {
        src: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper className={isOpen && "is-open"}>
      <Header
        lightLogo={data.lightLogoFluid.src.fluid}
        logo={data.logo.src.fluid}
        google={data.google.src.fluid}
        menuHandler={toggleMenu}
        isOpen={isOpen}
      />
      <Main className={isOpen && "is-open"}>{children}</Main>
      <Footer className={isOpen && "is-open"}>
        <Img fluid={data.lightLogoFluid.src.fluid} />
      </Footer>
      <Overlay className={isOpen && "is-open"} onClick={toggleMenu} />
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
