import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { MdEmail } from "react-icons/md"
import { FaFacebookF, FaPhone } from "react-icons/fa"

import { useSiteMeta } from "../hooks/siteMeta"
import { ACCENT_COLOR } from "../consts/color"

const StyledSection = styled.div`
  min-height: 40px;
  display: none;

  @media all and (min-width: 769px) {
   display: block;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  background-color: ${ACCENT_COLOR};
`

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 1.125em;
  display: flex;
  align-items: center;

  & svg {
    margin-right: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`
const GooglePartner = styled(Img)`
  max-width: 185px;
  width: 100%;
  margin: -0.625em 2.5em;
`

const Infobar = ({ google }) => {
  const { phone, email } = useSiteMeta()
  return (
    <StyledSection>
      <Container>
        <Link href={`tel:${phone.split(' ').join('')}`}><FaPhone /> {phone}</Link>
        <GooglePartner fluid={google} fadeIn={false} loading='eager'/>
        {/* <Link href={`mailto:${email}`}>
          <MdEmail /> {email}
        </Link> */}
        <Link href='https://www.facebook.com/gridmarketing.hu' target="_blank" rel="noreferrer noopener">
          <FaFacebookF/> gridmarketing
        </Link>
      </Container>
    </StyledSection>
  )
}

export default Infobar
